




















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import tipos_contratoModule from "@/store/modules/tipos_contrato-module";
import { tipos_contrato } from "@/shared/dtos/tipos_contrato";

@Component({
  components: {},
})
export default class tipos_contratoFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: tipos_contrato = new tipos_contrato();

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await tipos_contratoModule.gettipos_contrato(this.getparamId().Parametro);
      this.item_pantalla = tipos_contratoModule.tipos_contrato;
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 10, 6);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.codigo,
      "Código",
      "codigo",
      50
    ).Dimesiones(12, 10, 6);
  }

  public Insertar(object: tipos_contrato) {
    tipos_contratoModule.guardartipos_contrato(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: tipos_contrato) {
    tipos_contratoModule.modificartipos_contrato(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
